import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Layout, Card, Spin } from 'antd';
import AuthActions from './LoginPage/reducer';
import NewPasswordForm from '../Components/Forms/NewPasswordForm';
import LoginHeader from '../Components/LoginHeader';
import Images from '../Images';

function NewPasswordPage({ error, fetching, match, isMobile, ...props }) {
  const { t } = useTranslation();

  function handleSubmit(password) {
    if (fetching) return
    props.createUserPassword(password, false);
  }

  useEffect(() => {
    document.title = 'Create New Password - Dropship';
  },[])

  useEffect(() => {
    if (error) props.resetError();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [error])

  return (
    <Layout>
      <Spin size="large" spinning={fetching}>
        <div className="login-row">
          <LoginHeader />

          <div className="login-content">
            <div className="content-main">
              <div className="content-left content-left_32">
                <Card className="login-card">
                  <h2 className="card-title">{t('Create New Password')}</h2>
                  <div className="text-content">
                    {t(
                      'Your new password must be different from previously used passwords',
                    )}
                  </div>
                  <NewPasswordForm onSubmit={handleSubmit} error={error} />
                </Card>
              </div>

              <div className="content-right large">
                <div
                  className="bg"
                  style={{
                    backgroundImage: `url(${
                      isMobile
                        ? Images.createPasswordMobile
                        : Images.createPassword
                    })`,
                    maxWidth: 565,
                    margin: '0 auto',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  error: state.auth.error,
  fetching: state.auth.fetching,
  isMobile: state.nav.isMobile,
});

// wraps dispatch to create nicer functions to call within our component
const mapDispatchToProps = (dispatch) => ({
  resetError: () => dispatch(AuthActions.authErrorReset()),
  createUserPassword: (password, check) =>
    dispatch(AuthActions.signUpRequest(password, check)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPasswordPage);
