import React from 'react';
//import cls from 'classname';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divider, Dropdown, Switch } from 'antd';
import Icon from '../../../Icon';
import './ChartBlock.less';

//diff titles for diff reports
const title = {
  revenue: 'Total Revenue',
  sales: 'Total Sales',
  unique_products_sold: 'Total Unique Products Sold',
  ad_spot: 'Ad Engagement',
  ad_spot_date: 'Ad Creation Date',
};

export const ChartBlock = (
  {
    type,
    subTitle,
    totalCount,
    link,
    isMobile = false,
    adsInfo = false,
    adsQuantity = 4,
    sameLandingPageToggleText = null,
    historicalDataViewToggleText = null,
    sameLandingPage = false,
    setSameLandingPage = () => null,
    historicalDataView = true,
    setHistoricalDataView = () => null,
    id = null,
    showRawData = false,
    rawTotalCount,
    ...props
  }) => {

  const { t } = useTranslation();

  //total number format
  let totalFormat = (value) => new Intl.NumberFormat(
    'en-US',
    type === 'revenue'
      ? { style: 'currency', currency: 'USD' }
      : { style: 'decimal', maximumFractionDigits: 0 },
  ).format(value);

  return (
    <div className="chart-block"
         id={id}
    >
      <div className="chart-block_header">
        <span className="chart-block_title">
          {
            showRawData ?
              <span>
                {t(title[type])}<span style={{ margin: '0 5px' }}>/</span><span
                style={{ color: '#D71313' }}>Raw {t(title[type])}</span>
              </span>
              :
              t(title[type])
          }
          <span style={{ display: isMobile ? 'block' : 'inline-block', paddingLeft: isMobile ? 0 : 16 }}
                className="chart-block_sub-title">{subTitle}</span>
        </span>
        {
          adsInfo ?
            <span className="ads_quantity">
              {
                adsQuantity === 1 ?
                  t('Based On: _quantity_ Ad With Post Link', { quantity: adsQuantity })
                  :
                  t('Based On: _quantity_ Ads With Post Link', { quantity: adsQuantity })
              }
            </span>
            :
            null
        }
        {
          //link to report page
          link && <Link className="chart-block_link"
                        to={
                          {
                            pathname: link,
                            state: {
                              name: t(title[type]),
                              link: `/sales-tracker/store/${title}`,
                            },
                          }}>
            {t('View Report')}
            <Icon type={'arrow_link'} role={'icon'} />
          </Link>
        }
        {
          !sameLandingPageToggleText && !historicalDataViewToggleText ?
            null
            :
            !sameLandingPageToggleText && historicalDataViewToggleText ?
              <div className="switch-option"
                   style={{
                     ...(!sameLandingPageToggleText && !isMobile && {
                       marginRight: 'auto',
                       marginLeft: 'auto',
                     }),
                   }}
              >
                {
                  isMobile ?
                    null
                    :
                    <p onClick={() => setHistoricalDataView((prev) => !prev)}
                    >
                      {historicalDataViewToggleText?.[0]}
                    </p>
                }
                <Switch checked={historicalDataView}
                        onChange={(state) => setHistoricalDataView(state)}
                />
                <p onClick={() => setHistoricalDataView((prev) => !prev)}
                >
                  {
                    isMobile ?
                      historicalDataViewToggleText?.[historicalDataView ? 1 : 0]
                      :
                      historicalDataViewToggleText?.[1]
                  }
                </p>
              </div>
              :
              <Dropdown dropdownRender={() => (
                <div className={'actions-options-dropdown'}>
                  <div className="switch-option">
                    <Switch checked={sameLandingPage}
                            onChange={(state) => setSameLandingPage(state)}
                    />
                    <p onClick={() => setSameLandingPage((prev) => !prev)}
                    >
                      {sameLandingPageToggleText}
                    </p>
                  </div>
                  <div className="switch-option">
                    <Switch checked={historicalDataView}
                            onChange={(state) => setHistoricalDataView(state)}
                    />
                    <p onClick={() => setHistoricalDataView((prev) => !prev)}
                    >
                      {
                        historicalDataViewToggleText?.[historicalDataView ? 1 : 0]
                      }
                    </p>
                  </div>
                </div>
              )}
                        destroyPopupOnHide={true}
                        trigger={['click']}
                        placement="bottomRight"
                        getPopupContainer={(trigger) => trigger.parentNode}
                        overlayClassName={'filter-preset-cell-menu-wrapper'}
              >
          <span className={'actions-options'}>
            <Icon type="three_dots" role="button" width={24} height={24} />
          </span>
              </Dropdown>
        }
      </div>
      <span className="chart-block_total-count">
        {
          showRawData ?
            <span>
              {totalFormat(totalCount)}<span style={{ margin: '0 5px' }}>/</span><span
              style={{ color: '#D71313' }}>{totalFormat(rawTotalCount)}</span>
            </span>
            :
            totalFormat(totalCount)
        }
      </span>

      <Divider type={'horizontal'} style={{ margin: '16px 0' }} />

      {
        props.children
      }
    </div>
  );
};

export default ChartBlock;
