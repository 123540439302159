import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Layout, Spin } from 'antd';
import { withResizeDetector } from 'react-resize-detector';
import i18n from '../../i18n';
import dayjs from 'dayjs';
import { PrivateRoute } from '../../Components/PrivateRoute';
import StartupPage from '../StartupPage';
import LoginPage from '../LoginPage';
import SignUpPage from '../SignUpPage';
import CheckoutSuccessPage from '../CheckoutSuccessPage';
import ClickFunnelsPage from '../ClickFunnelsPage';
import InvoicePage from '../InvoicePage/index';
import Collections from '../CollectionsModals';
import openNotification from '../../Components/Notification';
import NotificationError from '../../Icon/img/NotificationError';
// REDUCERS
import NavActions from '../../Redux/NavRedux';
import ErrorsActions from '../../Redux/ErrorsRedux';
import AuthActions from '../LoginPage/reducer';
import StartupCreators from '../../Redux/StartupRedux';
// HOC
import useWindowSize from '../../hooks/use-window-size';
import withAuth from '../../hoc/withAuth';
import Spinner from '../../Components/Spinner';
import TrialFinished from "../../Components/Modal/TrialFinished";
import PendingPaymentModal from '../../Components/Modal/PendingPaymentModal';
import SettingCreators from "../SettingPage/reducer";
import GettingStartedButton from "../../Components/Button/GettingStartedButton";
import utc from "dayjs/plugin/utc";
import InternalPages from './InternalPages';
import CheckMailboxPage from '../CheckMailboxPage';
import RestorePasswordPage from '../RestorePasswordPage';
import VerifyCodePage from '../VerifyCodePage';
import NewPasswordPage from '../NewPasswordPage';
import ExpiredPage from '../ExpiredPage';
import AlreadyRegisteredPage from '../AlreadyRegisteredPage';
import OnBoardingPlanNew from '../OnboardingPlanNew';
import CheckEmailCode from '../CheckEmailCode';
import OnBoardingCheckout from '../OnboardingCheckout';

dayjs.extend(utc)

function Routess(props) {
  const {
    width,
    auth,
    theme,
    navSetProp,
    userInfo,
    isAdmin,
    isMobile,
    error,
    verifyToken,
    startup,
    dispatch,
    isPasswordCreated,
    checkOauth
  } = props;

  const socketRef = useRef(null);
  const [, innerHeight] = useWindowSize();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname, search } = location;
  const [visible, setVisible] = useState(false);
  const [visiblePendingModal, setVisiblePendingModal] = useState(false);
  const [visibleGettingStarted, setVisibleGettingStarted] = useState(false);

  // let deadline = userInfo?.subscriptions?.[0]?.expire_date ? dayjs(userInfo?.subscriptions?.[0]?.expire_date) : null;
  //
  // let timer = useRef(null);

  // STARTUP
  useEffect(() => {
    dispatch(StartupCreators.startup(location));

    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    });

    if (location.search.includes('show_help')) window.Intercom('showNewMessage');

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    document.querySelector('meta[name="theme-color"]').setAttribute('content', theme === 'dark' ? '#030625' : '#ffffff');
    document.body.style.backgroundColor = theme === 'dark' ? '#030625' : '#ffffff';
  }, [theme])

  useEffect(() => {
    if (innerHeight) document.documentElement.style.setProperty('--app-height', `${innerHeight}px`);
  }, [innerHeight]);

  // REFRESH TOKEN
  useEffect(() => {
    if (auth?.accessToken && auth?.userInfo?.onboarding_finished) {
      verifyToken(auth.accessToken, search.includes('extension') ? 'extension' : null);
    }
    if (auth?.accessToken && auth?.userInfo) {
      let socket = new WebSocket(
        `wss://${process.env.REACT_APP_BASE_WSS}/ws/user/${auth?.userInfo?.id}/?token=${auth?.accessToken}`
      );

      socket.onopen = () => {
        socketRef.current = socket;
      };

      socket.onmessage = (event) => {
        let data = JSON.parse(event?.data)
        if (data?.type === 'subscription.changed') {
          props.updateProfile({subscription: [{...data?.subscription}]});
        }
      };

      socket.onerror = (error) => {
        socket.close();
      };

      socket.onclose = (event) => {
        console.log(`WebSocket connection closed: ${event.code}`);
      };

      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        name: `${auth?.userInfo?.first_name} ${auth?.userInfo?.last_name}`, // Full name
        email: auth?.userInfo?.email, // Email address
        created_at: dayjs().unix(), // Signup date as a Unix timestamp
      });
    } else {
      if (socketRef.current) {
        socketRef.current.close();
        socketRef.current = null;
      }
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [auth?.accessToken, auth?.refreshToken]);

  // ERRORS
  useEffect(() => {
    if (error) {
      if (error.message) {
        for (const i in error.message) {
          openNotification({
            type: 'error',
            icon: <NotificationError />,
            message: `${i}: ${error.message[i]}`,
            style: { minWidth: '716px' },
            getContainer: userInfo?.onboarding_finished ? document.getElementById('global-wrap') : document.body,
            duration: 3,
            key: i,
          });
        }
      } else if (typeof error === 'string') {
        openNotification({
          type: 'error',
          icon: <NotificationError />,
          message: error,
          style: { minWidth: '716px' },
          getContainer: userInfo?.onboarding_finished ? document.getElementById('global-wrap') : document.body,
          duration: 3,
          key: 'error notification',
        });
      } else {
        for (const i in error) {
          openNotification({
            type: 'error',
            icon: <NotificationError />,
            message: `${i}: ${error[i]}`,
            style: { minWidth: '716px' },
            getContainer: userInfo?.onboarding_finished ? document.getElementById('global-wrap') : document.body,
            duration: 3,
            key: i,
          });
        }
      }
      props.errorReset();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [!!error]);

  // RESIZE
  useEffect(() => {
    const correctWidth = width || window.innerWidth;
    if (correctWidth > 768) {
      navSetProp('isDesktop', true);
      navSetProp('isMobile', false);
    } else {
      navSetProp('isDesktop', false);
      navSetProp('isMobile', true);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [width, !!userInfo]);

  useEffect(() => {
    if (userInfo?.show_intercom_tutorial && userInfo?.onboarding_finished && !window.location.pathname.includes('checkout/success')) setVisibleGettingStarted(true)
    else setVisibleGettingStarted(false);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [userInfo?.show_intercom_tutorial, userInfo?.onboarding_finished, window.location.pathname])

  // SET LANGUAGE
  useEffect(() => {
    i18n.changeLanguage(userInfo?.language || 'en');
    dayjs.locale(userInfo?.language === 'zh-hans' ? 'zh-cn' : (userInfo?.language || 'en'));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [userInfo?.language]);

  useEffect(() => {
    window.Intercom("update");
    if (!pathname.includes('setting') && (!Boolean(userInfo?.subscriptions?.[0]) || userInfo?.subscriptions?.[0]?.payment_status?.id === 7)
      && !isAdmin && userInfo?.onboarding_finished && !checkOauth) {
      setVisible(true)
    } else {
      setVisible(false)
    }
    if (!pathname.includes('setting') && userInfo?.subscriptions?.[0]?.payment_status?.id === 2
      && !isAdmin && userInfo?.onboarding_finished) {
      setVisiblePendingModal(true)
    } else {
      setVisiblePendingModal(false)
    }
    // if (deadline && !pathname.includes('setting') && dayjs(deadline).diff(dayjs.utc(dayjs()), 'hours') < 1) {
    //   timer.current && clearTimeout(timer.current);
    //   timer.current = setTimeout(() => {
    //     props.getSubscriptionsList('active');
    //   }, dayjs(deadline).diff(dayjs.utc(dayjs()), 'milliseconds'))
    // }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  },[pathname, isPasswordCreated])

  if (startup?.success === false && !auth.isValidRefreshToken) {
    return (
      <Layout>
        <Routes>
          <Route path="*" element={<StartupPage />}/>
        </Routes>
      </Layout>
    );
  }

  Spin.setDefaultIndicator(<Spinner/>);

  return (
      <Layout className="main-layout">
        <Routes>
          <Route  path="/invoice/:hash" element={<InvoicePage />}/>
          <Route  path="/" element={<StartupPage />}/>
          <Route  path="/login" element={<LoginPage />}/>
          <Route  path="/login/:hash" element={<LoginPage />}/>
          <Route  path="/sign-up" element={<SignUpPage />}/>
          <Route  path="/sign-up/:hash" element={<SignUpPage />}/>
          <Route path="/add-email" element={<SignUpPage />}/>

          <Route
            path="/onboarding/checkout/success"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && !isAdmin}
                            >
                <CheckoutSuccessPage />
              </PrivateRoute>
            }
          />

          <Route
            path="/onboarding/checkout/offers"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && !isAdmin}
              >
                <ClickFunnelsPage />
              </PrivateRoute>
            }
          />

          <Route
            path="offers"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin}
              >
                <ClickFunnelsPage />
              </PrivateRoute>
            }
          />

          <Route path="/check-mailbox" element={<CheckMailboxPage />}/>
          <Route  path="/sign-up/confirm-email/:hash" element={<OnBoardingPlanNew />}/>
          <Route path="/sign-up/confirm/:hash" element={<CheckEmailCode />}/>
          <Route  path="/restore-password" element={<RestorePasswordPage />}/>
          <Route path="/verify-code" element={<VerifyCodePage />}/>
          <Route path="/restore-password/:hash" element={<NewPasswordPage />}/>
          <Route path="/expired" element={<ExpiredPage />}/>
          <Route path="/already-registered" element={<AlreadyRegisteredPage />}/>

          <Route
            path="/onboarding/plan"
            element={
                <PrivateRoute redirectPath={'/login'}
                              auth={!!auth?.accessToken && !userInfo?.['onboarding_finished'] && !isAdmin}>
                  <OnBoardingPlanNew />
                </PrivateRoute>
            }
          />
          <Route
            path="/onboarding/checkout/:mode"
            element={<OnBoardingCheckout />}
          />
          <Route
            path="/onboarding/checkout/:mode/:intentId"
            element={<OnBoardingCheckout />}
          />

          <Route path="*" element={<InternalPages />}/>
        </Routes>
        <Collections auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin}/>

        <TrialFinished visible={visible}
                       isMobile={isMobile}
                       theme={theme}
                       callBack={() => {
                         setVisible(false);
                         navigate('/setting/plan')
                       }}
        />
        <PendingPaymentModal visible={visiblePendingModal}
                             isMobile={isMobile}
                             theme={theme}
                             callback={(url) => {
                               setVisiblePendingModal(false);
                               navigate(url)
                             }}
        />
        {
          visibleGettingStarted && !isMobile ?
            <GettingStartedButton />
            :
            null
        }
      </Layout>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  theme: state.nav.theme,
  userInfo: state.auth.userInfo,
  isPasswordCreated: state.auth.isPasswordCreated,
  startup: state.startup,
  isDesktop: state.nav.isDesktop,
  isMobile: state.nav.isMobile,
  isAdmin: state.auth.isAdmin,
  error: state.errors.data,
  checkOauth: state.shopifyStore.checkOauth
});

const mapDispatchToProps = (dispatch) => ({
  navSetProp: (key, value) => dispatch(NavActions.navSetProp(key, value)),
  errorReset: () => dispatch(ErrorsActions.errorReset()),
  refreshToken: (token) => dispatch(AuthActions.refreshTokenRequest({ token })),
  refreshTokenFailure: (data) => dispatch(AuthActions.refreshTokenFailure(data)),
  updateProfile: (data) => dispatch(AuthActions.updateProfile(data)),
  logout: () => dispatch(AuthActions.logout()),
  verifyToken: (token, isExtension) => dispatch(AuthActions.verifyTokenRequest({ token, isExtension})),
  getSubscriptionsList: (filter) =>
    dispatch(SettingCreators.getSubscriptionsListRequest(filter)),
  dispatch,
});

Routes.propTypes = {
  auth: PropTypes.shape({
    accessToken: PropTypes.string,
    refreshToken: PropTypes.string,
    isValidRefreshToken: PropTypes.bool,
  }),
  userInfo: PropTypes.shape({
    onboarding_finished: PropTypes.bool,
    language: PropTypes.string,
  }),
  startup: PropTypes.shape({
    success: PropTypes.bool,
  }),
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool,
  isAdmin: PropTypes.bool,
  error: PropTypes.any,
  dispatch: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(compose(withResizeDetector, withAuth)(Routess));
