/* eslint-disable import/no-named-as-default-member */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import UserCreators from '../../Containers/User/reducer';
import MainInfo from './MainInfo';
import LinkedAccounts from './LinkedAccounts';
import ShopifyStore from './ShopifyStore';
import AutoDsStore from "./AutoDsStore";

import AuthActions from '../../Containers/LoginPage/reducer';
import ShopifyStoreCreators from '../../Containers/ShopifyStore/reducer';
import AutoDsCreators from "../../Containers/AutoDS/reducer";

import './styles.less';

function AccountSubPage({ getShopifyStore, shopifyStore,  ...props }) {
  return (
    <div className="account-subpage" id="account-subpage">
      <Row className="account-main">
        <MainInfo
          {...props}
          updateUser={props.updatePersonalInfo}
        />
      </Row>
      <Row gutter={[{ sm: 0, md: 16 }, { sm: 0 }]}>
        <Col xs={24} md={12}>
          <LinkedAccounts
            userInfo={props.userInfo}
            socialAuthConnect={props.socialAuthConnect}
            socialAuthDelete={props.socialAuthDelete}
            socialAuthReset={props.socialAuthReset}
            socialAuthConnectResult={props.socialAuthConnectResult}
            socialAuthDeleteResult={props.socialAuthDeleteResult}
            isMobile={props.isMobile}
            socialAccount={props.socialAccount}
            socialAccountSave={props.socialAccountSave}
          />
        </Col>
        <Col xs={24} md={12}>
          <ShopifyStore
            theme={props.theme}
            userInfo={props.userInfo}
            shopifyStore={shopifyStore}
            getShopifyStore={getShopifyStore}
          />
        </Col>
      </Row>
      <Row gutter={[{ sm: 0, md: 16 }, { sm: 0 }]}
           style={{marginTop: 16}}
      >
        <Col xs={24} md={12}>
          <AutoDsStore
            theme={props.theme}
            userInfo={props.userInfo}
            autoDs={props.autoDs}
            getAutoStore={props.getAutoStore}
          />
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  socialAuthConnectResult: state.auth.socialAuthConnectResult,
  socialAuthDeleteResult: state.auth.socialAuthDeleteResult,
  saveFetching: state.users.saveFetching,
  verifyLastReqTime: state.users.verifyLastReqTime,
  saveAvatarFetching: state.users.saveAvatarFetching,
  saveResult: state.users.saveResult,
  theme: state.nav.theme,
  countries: state.onBoarding.countries,
  languages: state.setting.languages,
  isMobile: state.nav.isMobile,
  error: state.auth.error,
  userError: state.users.errors,
  socialAccount: state.auth.socialAccount,
  shopifyStore: state.shopifyStore,
  autoDs: state.autoDS,
});
const mapDispatchToProps = (dispatch) => ({
  updatePersonalInfo: (data) => dispatch(UserCreators.userSaveRequest(data)),
  updateAvatar: (data) => dispatch(UserCreators.userSaveAvatarRequest(data)),

  changeLang: (lang) => dispatch(UserCreators.changeLanguageRequest(lang)),

  socialAuthConnect: (accessToken, type, user) =>
    dispatch(AuthActions.socialAuthConnectRequest(accessToken, type, user)),
  socialAuthDelete: (type) =>
    dispatch(AuthActions.socialAuthDeleteRequest(type)),
  socialAuthReset: () => dispatch(AuthActions.socialAuthReset()),
  socialAccountSave: (name) => dispatch(AuthActions.socialAccountSave(name)),
  userSaveResultReset: () => dispatch(UserCreators.userSaveResultReset()),
  resetError: () => dispatch(AuthActions.authErrorReset()),
  changePassword: (password) =>
    dispatch(UserCreators.changePasswordRequest(password)),
  setPassword: (password) =>
    dispatch(UserCreators.setPasswordRequest(password)),
  userErrorsReset: () => dispatch(UserCreators.userErrorsReset()),

  sendCodePassword: (email) =>
    dispatch(UserCreators.sendCodePasswordRequest(email)),
  confirmCodePassword: (code) =>
    dispatch(UserCreators.confirmCodePasswordRequest(code)),

  createNewPassword: (code) =>
    dispatch(UserCreators.createNewPasswordRequest(code)),

  changeEmails: (email) => dispatch(UserCreators.changeEmailsRequest(email)),
  verifyEmail: (email) => dispatch(UserCreators.verifyEmailRequest(email)),
  setVerifyLastReqTime: (time) => dispatch(UserCreators.setVerifyLastReqTime(time)),
  getShopifyStore: () => dispatch(ShopifyStoreCreators.getShopifyStoresRequest()),
  getAutoStore: () => dispatch(AutoDsCreators.getAutoStoreRequest()),
});
export default connect(mapStateToProps, mapDispatchToProps)(AccountSubPage);
