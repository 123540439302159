import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import acc from 'accounting';
import Icon from '../../Icon';
import Images from '../../Images';
import utils from '../../Utils/utils';
import dayjs from 'dayjs';
import createHmac from'create-hmac';
import ButtonComponent from "../Button";
import {Divider, Tooltip} from "antd";
import Creators from "../../Containers/User/reducer";
import OnboardingActions from "../../Redux/OnboardingRedux";
import './styles.less';

const PlanCardOnboarding = (
  {
    plan,
    description,
    onSelectPlan,
    selectedPlan,
    switchPlan,
    planIndex,
    currentSubscription,
    isMobile,
    setVisible,
    setSubModal,
    isOnboarding=false
  }) => {
  const { t } = useTranslation();

  const useStripe = useSelector((state) => state?.auth?.userInfo?.use_stripe) || false;
  const churnkeyApiKey = useStripe ? 't60avwn34JPxxgQKwLeEbBRnj0QB0J1q' : 'CzX5a9ni0GZjaH3kPy0ALFKYUWiRrUxH';
  const churnkeyApiKeyTest = useStripe ? 'test_YHyQZPoRzHIQPLIU3XOJ2CRbql2s49iJ' : 'test_NhrO00GbElpQnLyzIyZTt21RFipwLBv3';
  const externalCustomerID = useSelector((state) => state?.auth?.userInfo?.[useStripe ? 'stripe_customer_id' : 'chargebee_customer_id']) || '';
  const externalSubscriptionID = useSelector((state) => state?.auth?.userInfo?.external_subscription_id) || '';
  const subWasCancelled = useSelector(state => state?.auth?.userInfo?.subscription_was_canceled);
  const subscriptionID = useSelector(state => state?.auth?.userInfo?.subscriptions?.[0]?.id);
  //for test
  const hash = createHmac('sha256', churnkeyApiKeyTest)?.update(externalCustomerID)?.digest('hex');

  //for prod
  // const hash = createHmac('sha256', churnkeyApiKey).update(externalCustomerID).digest('hex');

  const isYearlySubscription = useSelector(store => store.onBoarding.isYearlySubscription);
  const trialUsed = useSelector(store => store.auth.userInfo.trial_used);

  const priceType = isYearlySubscription ? 'yearly' : 'monthly';
  const yearlyPrice = plan?.prices?.['yearly']?.price * 12;
  const monthlyPrice = plan?.prices?.['monthly']?.price;
  const price = plan?.prices?.[priceType]?.price || 0;

  const isCancelledPlan = currentSubscription?.expire_date && currentSubscription?.next_payment_date === null;

  const isTrial = currentSubscription?.payment_status?.id === 6;
  const isPaused = currentSubscription?.payment_status?.id === 7;

  const isCurrentPlan = plan?.prices?.[priceType]?.id === currentSubscription?.plan?.id;
  const isScheduled = currentSubscription?.scheduled_plan && plan?.prices?.[priceType]?.id === currentSubscription?.scheduled_plan?.id;

  const dispatch = useDispatch();
  // const getUserInfo = () => dispatch(Creators.userRequest());
  const cancelSub = (id) => dispatch(OnboardingActions.cancelSubRequest(id));

  function handleSelectPlan() {
    if ((currentSubscription?.scheduled_plan && !isScheduled) || (isCancelledPlan && !isCurrentPlan)) return;
    else if (isPaused && isCurrentPlan) {
      setVisible(true);
      setSubModal('unpause');
    }
    else onSelectPlan(plan);
  }

  function handleClick(){
    if (isCurrentPlan && !isCancelledPlan && !isPaused && !isScheduled) {
      window.churnkey.init('restart', {
        customerId: externalCustomerID,
        subscriptionId: externalSubscriptionID,
        authHash: hash,
        record: false, // dev only
        onCancel() {
          if (subWasCancelled) cancelSub(subscriptionID)
        },
        // onClose() {
        //   setTimeout(() => getUserInfo(), 5000);
        // },
        appId: useStripe ? 'humrddvg8' : '45c8y6qwz',
        //for test
        mode: 'test',
        //for prod
        // mode: 'live',
        provider: useStripe ? 'stripe' : 'chargebee',
      });
    } else {
      handleSelectPlan();
    }
  }

  function getBtnText() {
    let text = !!currentSubscription ? 'Switch Plan' : ( trialUsed ? 'Select Plan' : 'Try Free For 7 Days');
    if (selectedPlan?.id === plan.id || switchPlan?.id === plan?.id) text = 'Selected';
    if (isCurrentPlan) {
      currentSubscription?.scheduled_plan || (currentSubscription?.expire_date && !currentSubscription?.next_payment_date) ?
        text = 'Current Plan'
        :
        text = 'Cancel Plan'
    }
    if (isScheduled || (isCancelledPlan && isCurrentPlan) || (isPaused && isCurrentPlan)) {
      text = isPaused ? 'Unpause Plan' : isCancelledPlan ? 'Activate Plan' : 'Scheduled';
      return (
        <>
          <Icon
            type="warning_slim"
            role="button"
            width={24}
            height={24}
            tooltipProps={{
              trigger: 'hover',
              placement: 'top',
              overlayClassName: 'subscription-tooltip scheduled',
              getPopupContainer: (trigger) => trigger.parentNode,
            }}
            titleText={
            isPaused ?
              t('Your _currentPlan_ plan is set to be paused until _date_',
                {
                  currentPlan: currentSubscription?.plan?.plan?.label,
                  date: dayjs(currentSubscription?.next_payment_date).format('MMM DD, YYYY'),
                },
              )
              :
              isCancelledPlan ?
                t('Your _currentPlan_ plan is set to be cancelled on _date_',
                  {
                    currentPlan: currentSubscription?.plan?.plan?.label,
                    date: dayjs(currentSubscription?.expire_date).format('MMM DD, YYYY'),
                  },
                )
                :
                t('Your _currentPlan_ plan is set to be changed on _date_ to _scheduledPlan_',
                  {
                    currentPlan: currentSubscription?.plan?.plan?.label,
                    date: dayjs(currentSubscription?.expire_date || currentSubscription?.next_payment_date).format('MMM DD, YYYY'),
                    scheduledPlan: currentSubscription?.scheduled_plan?.name,
                  },
                )
            }
          />
          {t(text)}
          <Icon role="button" type="close" width={24} height={24}/>
        </>
      );
    }
    if ((currentSubscription?.scheduled_plan || (currentSubscription?.expire_date && !currentSubscription?.next_payment_date) || isPaused) && !isScheduled && !isCurrentPlan) {
      return (
        <>
          <Icon
            type={'attention_outline'}
            role="button"
            width={24}
            height={24}
            tooltipProps={{
              trigger: 'hover',
              placement: 'top',
              overlayClassName: 'subscription-tooltip scheduled',
              getPopupContainer: (trigger) => trigger.parentNode,
            }}
            titleText={t('To switch to this plan, you need to cancel your active scheduling.')}
          />
          {t(text)}
        </>
      );
    }

    return t(text);
  }

  return (
    <div className={'plan-card-onboarding'}>

      <div className={cls('img-wrp')}/>
      <img src={Images[`plan_${planIndex}`]}
           alt=""
           className={cls(`drop-size_${planIndex}`)}
      />

      <div className={'tags-wrp'}>
        {
          (isYearlySubscription && plan?.name !== 'free')
            ? <span className="tags">{`${t('SAVE')} $${acc.formatNumber(monthlyPrice * 12 - yearlyPrice, 0)}`}</span>
            : null
        }
        {plan?.name === 'standard' ? <span className="tags">{t('most popular')}</span> : null}

      </div>

      <div className="info-wrp">
        <span className="info-title">
          <span>
            {utils.toUpperLatter(t(plan?.name))}
          </span>
          <span className="info-title_price">
           {
             (isYearlySubscription && plan?.name !== 'free')
               ? <span className="info-title_price-annually">
                 {`$${acc.formatNumber(yearlyPrice, 0)} ${t('billed annually')}`}
           </span>
               : null
           }
            {`$${price}/mo`}
          </span>

        </span>
        <span className="info-text">{description}</span>
      </div>

      <Divider type={'horizontal'} style={{margin: '16px auto 0'}} />

      {
        plan?.limits?.map(el => (
          <div key={el.id} className={cls('info-wrp info-wrp_border', { disabled: !el.active })}>
            <span className="info-text">
              {el.scope.value}

              <Icon type={'attention_outline'}
                    role={'button'}
                    width={16}
                    height={16}
                    btnType={cls({ disabled: !el.active })}
                    tooltipProps={{
                      placement: 'right',
                      trigger: isMobile ? 'click' : 'hover',
                      arrow: true,
                      destroyTooltipOnHide: true,
                      overlayClassName: 'info-text_tooltip',
                      getPopupContainer: (trigger) => trigger.parentNode,
                    }}
                    titleText={!el.active ? null : el.tooltip}
              />
            </span>
            <span className="info-text value">
              {
                el.value
                  ?
                  el?.limit_type?.id === 'partial_display' ?
                    `${el?.value}`
                    :
                    (
                      `${el.value} ${
                        el.rate_unit
                          ? `${el?.['limit_unit']?.value} / ${el.rate_unit.value}`
                          : (el?.scope?.id === 'product_drops' ? `/${el?.['limit_unit']?.value}` : el?.['limit_unit']?.value)
                      }`)
                  : el?.limit_type?.id === 'partial_display' ?
                  <span className={'hyphen'}/>
                  : t(el.active ? 'Unlimited Use' : 'Coming Soon')
              }
            </span>
          </div>
        ))

      }

      {((isTrial || currentSubscription?.payment_status?.id === 2) && isCurrentPlan) ? (
        <div className="plan-card-onboarding-btn-wrapper">
          <ButtonComponent type="primary"
                           id={(isCurrentPlan && !isCancelledPlan) ? 'cancel-current-sub' : null}
                  className={cls('btn-primary border-only',
                    {
                      'switch': switchPlan?.id === plan?.id,
                      'active': (selectedPlan?.id === plan.id || (isCurrentPlan && !isCancelledPlan && !isPaused)) && !isScheduled,
                      'scheduled': isScheduled || (isCancelledPlan && isCurrentPlan),
                      'scheduled-current': ((isScheduled || (isCancelledPlan && isCurrentPlan)) && isCancelledPlan) || (isCurrentPlan && isScheduled),
                      disabled: (currentSubscription?.scheduled_plan && !isScheduled) || (isCancelledPlan && !isCurrentPlan),
                    })}
                  disabled={(currentSubscription?.scheduled_plan && !isScheduled) || (isCancelledPlan && !isCurrentPlan)}
                  onClick={handleClick}
          >
            {
              getBtnText()
            }
          </ButtonComponent>

          {
            isTrial ?
              currentSubscription?.scheduled_plan || ((currentSubscription?.expire_date && !currentSubscription?.next_payment_date) || isPaused) ?
                <Tooltip placement="top"
                         title={'To perform the action, cancel scheduling'}
                         overlayClassName={'subscription-tooltip scheduled skip'}
                         arrow={true}
                         trigger={isMobile ? 'click' : 'hover'}
                         getPopupContainer={(trigger) => trigger.parentNode}
                         destroyTooltipOnHide={true}
                >
                  <ButtonComponent type="primary"
                                   className={cls('btn-primary skip-trial skip-trial-disabled')}
                                   onClick={null}
                  >
                    {t('Skip Trial & Pay')}
                  </ButtonComponent>
                </Tooltip>
                :
                <ButtonComponent type="primary"
                                 className={cls('btn-primary skip-trial')}
                                 onClick={() => {
                                   setVisible(true);
                                   setSubModal('cancelTrial');
                                 }}
                                 disabled={false}
                >
                  {t('Skip Trial & Pay')}
                </ButtonComponent>
              :
              <ButtonComponent type="primary"
                               className={cls('btn-primary skip-trial')}
                               onClick={handleSelectPlan}
                               disabled={false}
              >
                {
                  t('Retry charge')
                }
              </ButtonComponent>
          }
        </div>
      )
        :
        <ButtonComponent type="primary"
                         id={(isCurrentPlan && !isCancelledPlan) ? 'cancel-current-sub' : null}
                         className={cls('btn-primary border-only',
                           {
                             'switch': switchPlan?.id === plan?.id,
                             'active': (selectedPlan?.id === plan.id || (isCurrentPlan && !isCancelledPlan && !isPaused)) && !isScheduled,
                             'scheduled': isScheduled || (isCancelledPlan && isCurrentPlan) || (isPaused && isCurrentPlan),
                             'scheduled-current': ((isScheduled || (isCancelledPlan && isCurrentPlan)) && isCancelledPlan) || (isPaused && isCurrentPlan) || (isCurrentPlan && isScheduled),
                             'is_onboarding': isOnboarding,
                             disabled: (currentSubscription?.scheduled_plan && !isScheduled) || (isCancelledPlan && !isCurrentPlan) || (isPaused && !isCurrentPlan),
                           })}
                         onClick={() => !((currentSubscription?.scheduled_plan && !isScheduled) || (isCancelledPlan && !isCurrentPlan) || (isPaused && !isCurrentPlan)) && handleClick()}
        >
          {
            getBtnText()
          }
        </ButtonComponent>
      }

    </div>
  );
};

export default PlanCardOnboarding;
