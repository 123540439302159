/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/order */
/* eslint-disable no-unused-vars */

import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import {useNavigate, useParams} from 'react-router';
import { Container } from '../../Components/Blocks';
import Icon from '../../Icon';
import cls from 'classname';
import Creators from '../User/reducer';
import OnboardingActions from '../../Redux/OnboardingRedux';
import SettingCreators from './reducer';
import CategoryCreators from '../../Redux/CategoryRedux';
import PlanCreators from '../../Redux/PlanRedux';
import AccountSubPage from '../../Components/Account';
import AccountSkeleton from '../../Components/Account/AccountSkeleton';
import BillingSkeleton from '../../Components/BillingSubPage/BillingSkeleton';
import { useTranslation } from 'react-i18next';
import NotificationsSubPage from '../../Components/NotificationsSubPage';
import NotificationsSceleton from '../../Components/NotificationsSubPage/NotificationsSceleton';
import PlanSubPage from '../../Components/PlanSubPage';
import BillingSubPage from '../../Components/BillingSubPage';
import PlanSkeleton from "../../Components/PlanSubPage/PlanSkeleton";
import ButtonComponent from "../../Components/Button";
import './styles.less';

const SettingPage = (
  {
    authFetching,
    userFetching,
    settingFetching,
    countriesFetching,
    getCountries,
    getLanguagesList,
    getUserInfo,
    confirmEmail,
    getNotifications,
    getSubscriptionsList,
    getPayments,
    getUserCountry,
    getCategory,
    getPlan,
    plansFetching,
    plans,
    ...props
  }) => {

  /* account, billing, plan*/
  const { hash } = useParams();
  const navigate = useNavigate();

  const [denyUserReq, setDenyUserReq] = useState(false);

  useEffect(() => {
    document.title = 'Settings - Dropship';
    if (hash) {
      if (['create_charge', 'account', 'billing', 'plan'].includes(hash)) {
        navigate(`/setting/${hash}`, {replace: true});
      } else {
        if (!/create_charge|account|billing|plan/i.test(hash)) {
          if (localStorage.getItem('dropshipVerifyEmail') === 'true') {
            setDenyUserReq(true);
          }
          confirmEmail({hash, isVerify: localStorage.getItem('dropshipVerifyEmail') === 'true'});
          navigate('/setting/account');
        }
      }
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if (hash === 'account' && !denyUserReq) {
      if(!props.onBoarding.countries) getCountries();
      if(!props.setting.languages.length) getLanguagesList();
      getUserInfo();
      getUserCountry();
    }

    if (hash === 'notifications') {
      getNotifications();
    }
    if (hash === 'billing') {
      getUserInfo();
      getPayments();
    }
    if (hash === 'plan') {
      props.setLastLanguagePlanPage(props.userInfo?.language);
      if(!props.onBoarding.countries) getCountries();
      if(!props.categories) getCategory();
      if (!plans?.length || props.userInfo?.language !== props.setting.lastLanguage) getPlan();
      getSubscriptionsList('active');
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [hash]);

  const { t } = useTranslation();

  const skeleton = {
    account: userFetching || settingFetching || countriesFetching || authFetching,
    billing: userFetching || settingFetching,
    plan: settingFetching || plansFetching,
    notifications: settingFetching,
  };

  const content = {
    account: skeleton.account ? <AccountSkeleton/> : <AccountSubPage/>,
    billing: skeleton.billing ? <BillingSkeleton/> : !props?.userInfo?.need_block_pages ? <BillingSubPage/> : null,
    notifications: skeleton.notifications ? <NotificationsSceleton/> : <NotificationsSubPage/>,
    plan: skeleton.plan ? <PlanSkeleton /> : !props?.userInfo?.need_block_pages ? <PlanSubPage/> : null,
  };

  function handleNavigate(subPage) {
    navigate(`/setting/${subPage}`);
  }

  return (

    <div className="settings-page">
      { !props?.userInfo?.need_block_pages ?
        <Row className="setting-navigate" align="center">
        <div className={`setting-navigate-slider ${hash}`}/>
        {!skeleton[hash] ? (
          <>
            <Col span={8}>
              <ButtonComponent
                className={cls('navigate-menu-item', {
                  active: hash === 'account',
                })}
                onClick={() => handleNavigate('account')}
              >
                <Icon
                  role="icon"
                  type="union"
                  outline={hash !== 'account'}
                  className={hash === 'account' ? 'active' : null}
                />
                {t('Account')}
              </ButtonComponent>
            </Col>
            <Col span={8}>
              <ButtonComponent
                className={cls('navigate-menu-item', {
                  active: hash === 'billing',
                })}
                onClick={() => handleNavigate('billing')}
              >
                <Icon
                  role="icon"
                  type="pocket"
                  className={hash === 'billing' ? 'active' : null}
                />
                {t('Billing')}
              </ButtonComponent>
            </Col>
            <Col span={8}>
              <ButtonComponent
                className={cls('navigate-menu-item', {
                  active: hash === 'plan',
                })}
                onClick={() => handleNavigate('plan')}
              >
                <Icon
                  role="icon"
                  type="plan"
                  active={hash === 'plan'}
                />
                {t('Plan')}
              </ButtonComponent>
            </Col>
          </>
        ) : (
          <div className="skeleton-navigete-page"/>
        )}
      </Row>
        :
        null
      }
      <Container className={`setting-content-${hash}`}>
        {content[hash]}
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state?.auth?.userInfo,
  authFetching: state.auth.fetching,
  userFetching: state.users.fetching,
  setting: state.setting,
  settingFetching: state.setting.fetching,
  onBoarding: state.onBoarding,
  plans: state.plan.plans,
  plansFetching: state.plan.plansFetching,
  categories: state.category.categories,
  countriesFetching: state.onBoarding.countriesFetching,
});
const mapDispatchToProps = (dispatch) => ({
  getUserInfo: () => dispatch(Creators.userRequest()),
  getCountries: () => dispatch(OnboardingActions.countriesRequest()),
  getLanguagesList: () => dispatch(SettingCreators.getLanguagesListRequest()),
  setLastLanguagePlanPage: (data) => dispatch(SettingCreators.setLastLanguagePlanPage(data)),
  getNotifications: () =>
    dispatch(SettingCreators.getNotificationsListRequest()),
  getSubscriptionsList: (filter) =>
    dispatch(SettingCreators.getSubscriptionsListRequest(filter)),
  confirmEmail: (hash, isVerify) => dispatch(Creators.confirmEmailsRequest(hash, isVerify)),
  getPayments: () => dispatch(SettingCreators.getPaymentsRequest()),
  getUserCountry: () => dispatch(SettingCreators.getUserCountryRequest()),
  getCategory: () => dispatch(CategoryCreators.categoriesRequest()),
  getPlan: () => dispatch(PlanCreators.plansRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingPage);
