import React, { useState, useEffect } from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AuthActions from './LoginPage/reducer';
import { Layout, Spin, message, Card, Statistic } from 'antd';
import ReactCodeInput from 'react-verification-code-input';
import LoginHeader from '../Components/LoginHeader';
import Images from '../Images';
import utils from '../Utils/utils';
import cls from 'classname';
import ButtonComponent from "../Components/Button";

const { Countdown } = Statistic;

function VerifyCodePage(props) {
  const { isMobile, error, fetching, resetError } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const { t } = useTranslation();

  const [isComplete, setIsComplete] = useState(false);
  const [code, setCode] = useState(null);
  const [children, setChildren] = useState(null);

  const [resendFetching, setResendFetching] = useState(false);
  const [deadline, setDeadline] = useState(null);

  const [errorText, setErrorText] = useState(null);

  useEffect(() => {
    document.title = 'Verify Email - Dropship';
    let data = document.getElementsByClassName('code-block')[0].firstElementChild.children;
    setChildren(data);
  }, []);

  useEffect(() => {
    if (children) {
      initCodeBlock(children);
    }
  }, [children]);

  useEffect(() => {
    if (error) {
      if (error.message) {
        setErrorText(Object.values(error.message)[0]);
      } else if (typeof error === 'string') {
        setErrorText(error);
      } else {
        for (let i in error) {
          message.error(`${i}: ${error[i]}`, 5);
        }
      }
      resetError();
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [error]);

  function onChange(value) {
    setErrorText(null);
    if (!isNaN(value) && value.length > 5) {
      setIsComplete(true);
      setCode(value);
    } else {
      setIsComplete(false);
      setCode(null);
    }
  }

  function initCodeBlock(collection) {
    Array.from(collection).forEach(el => {
      el.placeholder = '-';
    });
  }

  function handleResendEmail() {
    if (state) {
      setDeadline(Date.now() + 1000 * 30);
      setResendFetching(true);
      props.checkPassword(state);
    } else {
      navigate('/restore-password');
    }
  }

  function onFinish() {
    setResendFetching(false);
    setDeadline(null);
  }

  return (
    <Layout>
      <Spin size="large" spinning={fetching}>
        <div className="login-row">
          <LoginHeader/>
          <div className="login-content">
            <div className="content-main">
              <div className="content-left content-left_32">
                <Card className="login-card">
                  <h2 className='card-title' style={{ marginBottom: 12 }}>{t('Verify Email')}</h2>
                  <div className="text-content">
                    {t('Enter the 6-digit code sent to')}<br/><span className='mark-text'>{state}</span>
                  </div>
                  <div className={cls('error-text', { 'error-text_empty': !errorText })}>{errorText}</div>
                  <ReactCodeInput
                    className={`code-block${errorText ? ' error' : ''}`}
                    fieldWidth={48}
                    fieldHeight={48}
                    onChange={onChange}
                  />
                  <div className="verify-btn-block">
                    <ButtonComponent className="btn-primary" type="primary white"
                            href={`https://mail.${utils.checkMailDomain(state).link}.com`} target="_blank" rel="noopener noreferrer">
                      <img className="btn-icon icon-space" src={Images[utils.checkMailDomain(state).type]} alt=""/>
                      {t('Go To')} {utils.checkMailDomain(state).name}
                    </ButtonComponent>
                    <ButtonComponent type="primary"
                            htmlType="submit"
                            className="btn-primary btn-primary_animation small"
                            style={{ width: '100%', marginLeft: 16 }}
                            disabled={!isComplete}
                            onClick={() => {
                              if (fetching) return
                              props.confirmPassword(code);
                            }}
                    >
                      {t('Confirm')}
                    </ButtonComponent>
                  </div>
                  <div className='sign-up-link check-mail' style={{ justifyContent: 'space-between' }}>
                    {
                      resendFetching
                        ? <div className='resend-timer'>
                          <Countdown title=""
                                     value={deadline}
                                     onFinish={onFinish}
                                     format='ss'
                                     prefix={<span>{t('Resend In')} </span>}
                                     suffix={<span> {t('Sec...')}</span>}
                          />
                          <Spin />
                        </div>
                        : <span className='text-link' onClick={handleResendEmail}>{t('Resend Code')}</span>
                    }
                    <Link to="/restore-password">{t('Change Email')}</Link>
                  </div>
                </Card>
              </div>
              <div className="content-right large">
                <div className={'bg'} style={{
                  backgroundImage: `url(${isMobile ? Images.verifyCodeMobile : Images.verifyCode})`,
                  maxWidth: 613,
                  margin: '0 auto',
                }}/>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    isDesktop: state.nav.isDesktop,
    isMobile: state.nav.isMobile,
    error: state.auth.error,
    fetching: state.auth.fetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  confirmPassword: (code) => dispatch(AuthActions.confirmPasswordRequest(code)),
  checkPassword: (email) => dispatch(AuthActions.checkPasswordRequest(email)),
  resetError: () => dispatch(AuthActions.authErrorReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyCodePage);
