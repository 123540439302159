/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {Layout, Card, Statistic, Spin} from 'antd';
import ReactCodeInput from 'react-verification-code-input';
import cls from 'classname';
import Images from '../../Images';
import utils from '../../Utils/utils';
import ButtonComponent from "../Button";

const { Countdown } = Statistic;

function VerifyCodePage(props) {
  const {
    isMobile,
    email,
    onChangeModal,
    userError,
    saveResult,
    userSaveResultReset,
    userErrorsReset,
    sendCodePassword,
    confirmCodePassword,
  } = props;

  const { t } = useTranslation();

  const [isComplete, setIsComplete] = useState(false);
  const [code, setCode] = useState(null);
  const [key, setKey] = useState(1);
  const [children, setChildren] = useState(null);

  const [resendFetching, setResendFetching] = useState(false);
  const [deadline, setDeadline] = useState(null);

  useEffect(() => {
    const data =
      document.getElementsByClassName('code-block')[0].firstElementChild
        .children;
    setChildren(data);
  }, []);

  useEffect(() => {
    if (saveResult?.confirm) {
      onChangeModal('createNewPassword');
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [saveResult]);

  useEffect(() => {
    handleResendEmail();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  function onChange(value) {
    userErrorsReset();
    if (!isNaN(value) && value.length > 5) {
      setIsComplete(true);
      setCode(value);
    } else {
      setIsComplete(false);
      setCode(null);
    }
  }

  function handleResendEmail() {
    userSaveResultReset();
    userErrorsReset();
    setDeadline(Date.now() + 1000 * 30);
    if (children) {
      setCode(null);
      setKey(prev => prev + 1);
    }
    setResendFetching(true);
    sendCodePassword();
  }
  function handleConfirm() {
    userSaveResultReset();
    confirmCodePassword(code);
  }
  function onFinish() {
    setResendFetching(false);
    setDeadline(null);
  }

  const resendBlock = (
    <div
      className="sign-up-link check-mail"
      style={{ justifyContent: 'space-between' }}
    >
      {resendFetching ? (
        <div className="resend-timer">
          <Countdown
            title=""
            value={deadline}
            onFinish={onFinish}
            format="ss"
            prefix={<span>{t('Resend In')} </span>}
            suffix={<span> {t('Sec...')}</span>}
          />
          <Spin />
        </div>
      ) : (
        <span className='text-link' onClick={handleResendEmail}>
          {t('Resend Code')}
        </span>
      )}
    </div>
  );

  return (
    <Layout>
      <div className="forgot-row">
        <div className="login-content">
          <div className="content-main">
            <div className="content-left content-left_32">
              <Card className="login-card verify-modal">
                <h2 className="card-title" style={{ marginBottom: 12 }}>
                  {t('Verify Email')}
                </h2>
                <div className="text-content">
                  {t('Enter the 6-digit code sent to')}
                  <br />
                  <span className="mark-text">{email}</span>
                </div>

                <div
                  className={cls('error-text', {
                    'error-text_empty': !userError?.detail,
                  })}
                >
                  {t(userError?.detail)}
                </div>

                <ReactCodeInput
                  className={`code-block${userError?.detail ? ' error' : ''}`}
                  fieldWidth={48}
                  fieldHeight={48}
                  onChange={onChange}
                  key={key}
                  values={code === null ? ['','','','','',''] : code?.split('')}
                  placeholder={['-','-','-','-','-','-']}
                />
                {resendBlock}

                <div
                  className={cls('verify-btn-block', {
                    'double-btn-wrp': isMobile,
                  })}
                >
                  <ButtonComponent
                    className="btn-primary"
                    type="primary white"
                    href={`https://mail.${
                      utils.checkMailDomain(email).link
                    }.com`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="btn-icon icon-space"
                      src={Images[utils.checkMailDomain(email).type]}
                      alt=""
                    />
                    {t('Go To')} {utils.checkMailDomain(email).name}
                  </ButtonComponent>

                  <ButtonComponent
                    type="primary"
                    htmlType="submit"
                    className="btn-primary btn-primary_animation small"
                    style={!isMobile ? { width: '100%', marginLeft: 16 } : null}
                    disabled={!isComplete || userError?.detail}
                    onClick={handleConfirm}
                  >
                    {t('Confirm')}
                  </ButtonComponent>
                </div>
              </Card>
            </div>

            <div className="content-right large">
              <div
                className="bg"
                style={{
                  backgroundImage: `url(${
                    isMobile ? Images.verifyCodeMobile : Images.verifyCode
                  })`,
                  maxWidth: 613,
                  margin: '0 auto',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default VerifyCodePage;
