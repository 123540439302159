import React, {memo, useCallback, useEffect, useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {useParams} from 'react-router';
import {connect, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {Row, Col, Spin, Modal} from 'antd';
import BreadcrumbsActions from '../Breadcrumbs/actions';
import Creators from './reducer';
import ShopifyCreators from '../ShopifyStore/reducer';
import AutoDSCreators from "../AutoDS/reducer";
import ChartBlock from './components/ChartBlock';
import MainBlock from './components/MainBlock';
import Chart from '../../Components/Charts/Chart';
import {OverlayScrollbar} from '../../Components/ScrollBar';
import TrackerTable from './components/TrackerTable';
import StoreTrackerPageSkeleton from "./components/StoreTrackerPageSkeleton";
import Icon from "../../Icon";
import NumbersBreakdownDatabaseBlock from "../ProductDatabasePage/components/NumbersBreakdownDatabaseBlock";
import SalesTrackerNote from "./components/SalesTrackerNote";
import FreePlanErrorModal from "./components/FreePlanErrorModal";
import {dateOptions} from "../../Utils/utils";
import SalesTrackerUnavailableNote from "./components/SalesTrackerUnavailableNote";
import MainBlockNew from "./components/MainBlockNew";
import CanNotRemoveTracking from "../ProductDatabasePage/components/CanNotRemoveTracking";
import UntrackableDeleteModal from './components/UntrackableDeleteModal';
import StoreProductsModal from "./components/StoreProductsModal";
import StoreAppsModal from "./components/StoreAppsModal";
import PriceHistory from '../ProductDatabasePage/components/PriceHistory';
import cls from "classname";
import NavHeader from "./components/NavHeader";
import ProductCardsPage from "./components/ProductCardsPage";
import './styles.less';

export const StoreTrackerPage = (
  {
    isAdmin,
    fetching,
    checkLoading,
    detailedStoreLoading,
    detailedStoreNewLoading,
    error,
    userInfo,
    isMobile,
    salesTracker,
    setView,
    view,
    breadcrumbs,
    dispatch,
    getDetailedStoreInfo,
    getDetailedStoreInfoNew,
    getDetailedStoreInfoCheckRequest,
    getProductListByStore,
    getStoreProductsModal,
    getStoreProductsModalNext,
    resetStoreProductsModal,
    storeProductsModalTableDataLoading,
    storeProductsModalTableData,
    selectedStoreTableDataLoading,
    connectProduct,
    deleteProduct,
    getExpandableChart,
    downloadSalesReport,
    downloadRevenueReport,
    filterDates,
    setFilters,
    setPeriod,
    fetchingSpin,
    getStores,
    shopifyStore,
    changeVisibleModalShopify,
    activeView,
    setActiveView,
    filtersLoading,
    ...props
  }) => {
  //state to show skeleton
  const [firsLoading, setFirsLoading] = useState(true);

  const isVisibleModal = shopifyStore?.isVisibleModal;
  const navigate = useNavigate();
  const location = useLocation();
  const {id} = useParams();
  const breadCrumbs = breadcrumbs?.length ? [...breadcrumbs] : null;
  const {state} = location;

  const isTrial = userInfo?.subscriptions?.[0]?.payment_status?.id === 6;

  //3 charts info + selected store main info
  const detailedInfo = useSelector(store => store?.salesTracker?.selectedStore) || [];

  const stores = useSelector(store => store?.salesTracker?.stores?.results) || [];
  const searchResults = useSelector(store => store?.salesTracker?.stores?.searchResults) || [];

  const shopifyStores = useSelector(store => store['shopifyStore'].results) || [];
  const autoDS = useSelector(store => store?.autoDS);
  const initialUrl = document?.location?.pathname + (document?.location?.search || '');

  const detailedInfoNew = stores?.filter(el => +el.id === +id)?.[0] || searchResults?.filter(el => +el.id === +id)?.[0] || {};
  //3 charts page table data
  const storeProducts = useSelector(store => store?.salesTracker?.selectedStoreTableData) || [];

  const record = {data: {...detailedInfoNew}}

  const minDate = filterDates?.min;
  const maxDate = filterDates?.max;
  const timeZone = detailedInfoNew?.store_timezone ? detailedInfoNew?.store_timezone.split('UTC')[1] : null;

  //table pagination options
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortOrder, setSortOrder] = useState(null);
  // modal show/hide
  const [visible, setVisible] = useState(false);
  const [showRawData, setShowRawData] = useState(false);
  const [modal, setModal] = useState('');
  ////numbers breakdown row to calc
  const [recordToCalc, setRecordToCalc] = useState(null);
  const [recordToPriceHistory, setRecordToPriceHistory] = useState(null);

  // const showFreePlanError = salesTracker?.showFreePlanError;
  const showFreePlanError = false;
  const isShowExtensionNotification = window.location?.search.includes('show_notification')

  useEffect(() => {
    if (!Object.keys(detailedInfoNew).length && isShowExtensionNotification) {
      getStores({page: 1,page_size: 50});
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [detailedInfoNew?.title])

  useEffect(() => {
    document.title = `Sales Tracker - Dropship`;
    if (isVisibleModal) changeVisibleModalShopify({ isVisibleModal: null, initialUrl: null, importProductId: null });
    if(!stores.length && !isShowExtensionNotification) navigate('/sales-tracker/stores', {replace: true});
    if (firsLoading) dispatch(Creators.resetIsAvailable());
    if (
      detailedInfoNew?.first_sale_date
      && dayjs().diff(dayjs(detailedInfoNew?.first_sale_date), 'days') < 30
      && dayjs(minDate) < dayjs(detailedInfoNew?.first_sale_date)
    ) {
      const newData = dateOptions.filter(el => dayjs().utcOffset(timeZone ? timeZone : '+0:00').diff(dayjs(detailedInfoNew?.first_sale_date), 'days') >= el.days);
      let quantity = newData[newData?.length - 1]?.value?.split(' ')?.[1]
      let value = newData?.[newData?.length - 1]?.value?.split(' ')?.[2]
      let period = newData?.[newData?.length - 1]?.id
      let min = dayjs().diff(dayjs(detailedInfoNew?.first_sale_date), 'days') >= 1
      && dayjs().diff(dayjs(detailedInfoNew?.first_sale_date), 'days') < 7 ?
        dayjs(detailedInfoNew?.first_sale_date).format('YYYY-MM-DD')
        :
        dayjs().utcOffset(timeZone ? timeZone : '+0:00').subtract([1, 2].includes(period) ? quantity : quantity - 1, value).format('YYYY-MM-DD');
      let max = dayjs().diff(dayjs(detailedInfoNew?.first_sale_date), 'days') >= 1
      && dayjs().diff(dayjs(detailedInfoNew?.first_sale_date), 'days') < 7 ?
        dayjs().format('YYYY-MM-DD')
          :
        [1,2].includes(period) ? min : dayjs().utcOffset(timeZone ? timeZone : '+0:00').format('YYYY-MM-DD');
      setFilters({report_period: {min, max}});
      setPeriod(dayjs().diff(dayjs(detailedInfoNew?.first_sale_date), 'days') >= 1
      && dayjs().diff(dayjs(detailedInfoNew?.first_sale_date), 'days') < 7 ?
        [...newData]
          :
        [newData[newData?.length - 1]]);
    } else if (minDate === null || maxDate === null) {
      let max = new Date(dayjs()).toISOString();
      let min = new Date(dayjs().subtract(30, 'days')).toISOString();
      setFilters({report_period: {min, max}})
      setPeriod([{id: 4, name: 'Last 30 days', value: 'Last 30 days', days: 30}])
    } else {
      const min = () => dayjs().utcOffset(timeZone ? timeZone : '+0:00').subtract(29, 'days').format('YYYY-MM-DD');
      const max = () => dayjs().utcOffset(timeZone ? timeZone : '+0:00').format('YYYY-MM-DD');
      setFilters({report_period: {min: min(), max: max()}})
      setPeriod([{id: 4, name: 'Last 30 days', value: 'Last 30 days', days: 30}])
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if (!firsLoading) {
      getDetailedStoreInfoCheckRequest({id: id, date_range: {min: minDate, max: maxDate}});
      getDetailedStoreInfo({
          id: id,
          date_range: {min: minDate, max: maxDate},
          raw_data: userInfo?.raw_data_access && showRawData
        });
      getDetailedStoreInfoNew({id: id});
      getProductListByStore({
        page: pageNumber,
        store_id: id,
        page_size: pageSize,
        ordering: sortOrder,
        date_range: {min: minDate, max: maxDate}
      });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [minDate, maxDate, showRawData])

  useEffect(() => {
    if (firsLoading) setFirsLoading(false)
    else {
      getProductListByStore({page: pageNumber, store_id: id, page_size: pageSize, ordering: sortOrder, date_range: {min: minDate, max: maxDate}});
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [pageNumber, pageSize, sortOrder]);

  //breadcrumbs display management
  useEffect(() => {
    if (breadCrumbs?.length >= 3) {
      props.removeBreadCrumb();
    } else if (breadCrumbs?.length === 2 && state) {
      props.addBreadCrumb({
        name: state?.name,
        link: state?.link,
      });
    } else if (breadCrumbs?.length < 2) {
      props.addBreadCrumb({
        name: detailedInfoNew?.title || (new URLSearchParams(window?.location?.search).get('title') ? decodeURIComponent(new URLSearchParams(window?.location?.search).get('title')) : id),
        link: state?.link || `/sales-tracker/stores/store/${id}`,
      });
    } else {
      props.clearBreadCrumbs();
      props.addBreadCrumb({
        name: 'Store Tracker',
        link: `/sales-tracker/stores`,
      });
      props.addBreadCrumb({
        name: detailedInfoNew?.title || (new URLSearchParams(window?.location?.search).get('title') ? decodeURIComponent(new URLSearchParams(window?.location?.search).get('title')) : id),
        link: state?.link || `/sales-tracker/stores/store/${id}`,
      });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [state]);

  useEffect(() => {
    if (showFreePlanError) {
      setModal('freePlanError');
      setVisible(true);
    }
  }, [showFreePlanError])

  const modalBlocks = {
    breakdown: <NumbersBreakdownDatabaseBlock product={recordToCalc} />,
    freePlanError: <FreePlanErrorModal  setModal={setModal}
                                        setVisible={setVisible}
                                        reset={props.resetFreePlanError}
    />,
    canNotRemoveTracking: <CanNotRemoveTracking />,
    storeProducts: <StoreProductsModal getStoreProductsModal={getStoreProductsModal}
                                       getStoreProductsModalNext={getStoreProductsModalNext}
                                       storeProductsModalTableDataLoading={storeProductsModalTableDataLoading}
                                       storeProductsModalTableData={storeProductsModalTableData}
                                       storeId={id}
                                       resetStoreProductsModal={resetStoreProductsModal}
                                       minDate={minDate}
                                       maxDate={maxDate}
                                       isMobile={isMobile}
    />,
    storeApps: <StoreAppsModal data={detailedInfo?.total?.store_apps} />,
    priceHistory: <PriceHistory product={recordToPriceHistory} isPD={false} />,
    untrackableDelete: <UntrackableDeleteModal setModal={setModal}
                                               record={record}
                                               setVisible={setVisible}
                                               deleteStore={props.deleteStore}
                                               deleteProduct={null}
                                               view={'stores'}
    />,
  }

  const modalBlocksWidth = {
    breakdown: 1040,
    freePlanError: 512,
    canNotRemoveTracking: 512,
    storeProducts: 1120,
    storeApps: 696,
    priceHistory: 1040,
    untrackableDelete: 512,
  }

  const handleChangePageSize = useCallback((value) => {
    setPageSize(value);
  }, []);

  const handleChangePageNumber = useCallback((value) => {
    setPageNumber(value);
  }, []);

  const handleSort = useCallback(({order, columnKey}) => {
    if (order) {
      const ordering = order === 'ascend' ? columnKey : `-${columnKey}`;
      setSortOrder(ordering);
    } else {
      setSortOrder(null);
    }
  }, []);

  const handleOpenModalShopifyConnect = (importProductId) => {
    if (shopifyStores.length) {
      dispatch(ShopifyCreators.changeVisibleModalShopify({ isVisibleModal: 'import', initialUrl, importProductId }));
    } else {
      dispatch(ShopifyCreators.changeVisibleModalShopify({ isVisibleModal: 'connect', initialUrl, importProductId: null }));
    }
  };

  const handleOpenModalAutoDSConnect = (importProductId) => {
    if (autoDS?.email) {
      if (autoDS?.stores?.length) dispatch(AutoDSCreators.changeVisibleModalAuto({ isVisibleModal: 'import', importProductId }));
      else dispatch(AutoDSCreators.changeVisibleModalAuto({ isVisibleModal: 'import_unavailable', importProductId: null }));
    } else dispatch(AutoDSCreators.changeVisibleModalAuto({ isVisibleModal: 'connect', importProductId }));
  }

  return (
    //custom scrollbars
    <OverlayScrollbar visibility="hidden">
      <div className="product-database-page sales-tracker-page " style={{paddingTop: 0}}>
        {
          activeView === 'overview' ?
            firsLoading || fetching || checkLoading || detailedStoreLoading || detailedStoreNewLoading || fetchingSpin ?
              <StoreTrackerPageSkeleton isMobile={isMobile} />
              :
              <>
                <NavHeader active={activeView}
                           setActive={setActiveView}
                           isMobile={isMobile}
                />

                {detailedInfo?.total?.is_deleted_from_st ?
                  <SalesTrackerUnavailableNote/>
                  :
                  null
                }

                {isMobile ? null : <SalesTrackerNote/>}

                <MainBlockNew data={{
                  title: detailedInfoNew?.title,
                  link: detailedInfoNew?.custom_domain,
                  logo: detailedInfoNew?.logo || detailedInfoNew?.favicon,
                  timeZone: timeZone,
                  record: detailedInfoNew,
                  totalInfo: detailedInfo?.total
                }}
                              setVisible={setVisible}
                              setModal={setModal}
                              isMobile={isMobile}
                />

                <MainBlock
                  data={{
                    title: detailedInfoNew?.title,
                    link: detailedInfoNew?.custom_domain,
                    logo: detailedInfoNew?.logo || detailedInfoNew?.favicon,
                    report: 'sales',
                    id: id,
                    reportName: 'Store Report',
                    dates: {min: minDate, max: maxDate},
                    // timeInfo - 1st sale date
                    timeInfo: detailedInfoNew?.first_sale_date,
                    timeZone: timeZone,
                  }}
                  downloadSalesReport={downloadSalesReport}
                  downloadRevenueReport={downloadRevenueReport}
                  dateOptions={dateOptions}
                  showRawData={showRawData}
                  setShowRawData={setShowRawData}
                  rawDataAccess={userInfo?.raw_data_access}
                />

                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <ChartBlock type={'revenue'}
                                subTitle={!isMobile ? minDate && maxDate ?
                                    `${dayjs(minDate).format('MMM DD, YYYY')} - ${dayjs(maxDate).format('MMM DD, YYYY')}`
                                    :
                                    `${dayjs(detailedInfo?.charts?.revenue[0]?.date).format('MMM DD, YYYY')} - ${dayjs(detailedInfo?.charts?.revenue[detailedInfo?.charts?.revenue?.length - 1]?.date).format('MMM DD, YYYY')}`
                                  :
                                  null
                                }
                                totalCount={detailedInfo?.total ? detailedInfo?.total?.revenue : 0}
                                link={`/sales-tracker/stores/store/${id}/revenue`}
                                showRawData={showRawData}
                                rawTotalCount={Object.keys(detailedInfo?.raw_total || {})?.length ? detailedInfo?.raw_total?.revenue : 0}
                    >
                      <Chart data={
                        detailedInfo?.charts ?
                          [...detailedInfo?.charts?.revenue?.chart]
                          :
                          []
                          || []
                      }
                             type={'revenue'}
                             animation={false}
                             isMobile={isMobile}
                             withRawData={showRawData}
                             withReturns={true}
                             returnsData={detailedInfo?.charts?.revenue?.returns?.length ?
                               [...detailedInfo?.charts?.revenue?.returns]
                               :
                               []}
                             rawReturnsData={detailedInfo?.charts?.revenue?.raw_data_returns?.length ?
                               [...detailedInfo?.charts?.revenue?.raw_data_returns]
                               :
                               []}
                             rawData={detailedInfo?.charts?.revenue?.raw_data_chart?.length ?
                               [...detailedInfo?.charts?.revenue?.raw_data_chart]
                               :
                               []}
                      />
                    </ChartBlock>
                  </Col>

                  <Col span={isMobile ? 24 : null} md={isMobile ? null : 24} xl={isMobile ? null : 12}>
                    <ChartBlock type={'sales'}
                                totalCount={detailedInfo?.total ? detailedInfo?.total?.sales : 0}
                                link={`/sales-tracker/stores/store/${id}/sales`}
                                showRawData={showRawData}
                                rawTotalCount={Object.keys(detailedInfo?.raw_total || {})?.length ? detailedInfo?.raw_total?.sales : 0}
                    >
                      <Chart height={isMobile ? null : '150px'}
                             data={
                        detailedInfo?.charts ?
                          [...detailedInfo?.charts?.sales?.chart]
                          :
                          []
                          || []
                      }
                             type={'sales'}
                             animation={false}
                             isMobile={isMobile}
                             withRawData={showRawData}
                             withReturns={true}
                             returnsData={detailedInfo?.charts?.sales?.returns?.length ?
                               [...detailedInfo?.charts?.sales?.returns]
                               :
                               []}
                             rawReturnsData={detailedInfo?.charts?.sales?.raw_data_returns?.length ?
                               [...detailedInfo?.charts?.sales?.raw_data_returns]
                               :
                               []}
                             rawData={detailedInfo?.charts?.sales?.raw_data_chart?.length ?
                               [...detailedInfo?.charts?.sales?.raw_data_chart]
                               :
                               []}
                      />
                    </ChartBlock>
                  </Col>

                  <Col span={isMobile ? 24 : null} md={isMobile ? null : 24} xl={isMobile ? null : 12}>
                    <ChartBlock type={'unique_products_sold'}
                                totalCount={detailedInfo?.total ? detailedInfo?.total?.unique : 0}
                                link={`/sales-tracker/stores/store/${id}/unique_products_sold`}
                                showRawData={showRawData}
                                rawTotalCount={Object.keys(detailedInfo?.raw_total || {})?.length ? detailedInfo?.raw_total?.unique : 0}
                    >
                      <Chart height={isMobile ? null : '150px'}
                             data={
                        detailedInfo?.charts ?
                          [...detailedInfo?.charts?.unique?.chart]
                          :
                          []
                          || []
                      }
                             type={'unique_products_sold'}
                             animation={false}
                             isMobile={isMobile}
                             withRawData={showRawData}
                             withReturns={true}
                             returnsData={detailedInfo?.charts?.unique?.returns?.length ?
                               [...detailedInfo?.charts?.unique?.returns]
                               :
                               []}
                             rawReturnsData={detailedInfo?.charts?.unique?.raw_data_returns?.length ?
                               [...detailedInfo?.charts?.unique?.raw_data_returns]
                               :
                               []}
                             rawData={detailedInfo?.charts?.unique?.raw_data_chart?.length ?
                               [...detailedInfo?.charts?.unique?.raw_data_chart]
                               :
                               []}
                      />
                    </ChartBlock>
                  </Col>
                </Row>

                <TrackerTable fetching={fetching || fetchingSpin || checkLoading || detailedStoreLoading || detailedStoreNewLoading}
                              minDate={minDate}
                              maxDate={maxDate}
                              loading={selectedStoreTableDataLoading}
                              onSort={handleSort}
                              pageSize={pageSize}
                              setPageSize={handleChangePageSize}
                              pageNumber={pageNumber}
                              setPageNumber={handleChangePageNumber}
                              totalProducts={storeProducts?.count}
                              data={storeProducts?.results || []}
                              sortOrder={sortOrder}
                              report={'sales'}
                              setVisible={setVisible}
                              setModal={setModal}
                              setRecordToCalc={setRecordToCalc}
                              setRecordToPriceHistory={setRecordToPriceHistory}
                              connectProduct={connectProduct}
                              deleteProduct={deleteProduct}
                              getExpandableChart={getExpandableChart}
                              handleOpenModalShopifyConnect={handleOpenModalShopifyConnect}
                              handleOpenModalAutoDSConnect={handleOpenModalAutoDSConnect}
                              isTrial={isTrial}
                              isMobile={isMobile}
                              autoDS={autoDS}
                />
              </>
            :
              <>
                {
                  filtersLoading ?
                    null
                    :
                  <NavHeader active={activeView}
                            setActive={setActiveView}
                            isMobile={isMobile}
                  />
                }
                <ProductCardsPage minDate={minDate}
                                  maxDate={maxDate}
                                  detailedInfoNew={detailedInfoNew}
                                  timeZone={timeZone}
                                  downloadSalesReport={downloadSalesReport}
                                  downloadRevenueReport={downloadRevenueReport}
                                  dateOptions={dateOptions}
                                  id={id}
                                  handleOpenModalShopifyConnect={handleOpenModalShopifyConnect}
                />
              </>
        }

        <Modal
          className={cls("change-modal custom-modal", {
            'store-products-opened': modal === 'storeProducts' || modal === 'storeApps'
          })}
          getContainer={() => document.getElementById('global-wrap')}
          open={visible}
          {...(isMobile ? {transitionName: ''} : null)}
          centered={!isMobile}
          closeIcon={
            <Icon role="icon" type="close_modal" color="#707BA0" opacity={1}/>
          }
          width={modalBlocksWidth[modal]}
          footer={null}
          closable="true"
          onCancel={() => {
            setVisible(false);
            props.resetFreePlanError();
          }}
          destroyOnClose
        >
          <Spin size="large" spinning={fetching}>
            {modalBlocks[modal]}
          </Spin>
        </Modal>

      </div>
    </OverlayScrollbar>
  );
};

StoreTrackerPage.propTypes = {
  isAdmin: PropTypes.bool,
  fetching: PropTypes.bool,
  isMobile: PropTypes.bool,
  breadcrumbs: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  userInfo: state.auth.userInfo,
  isMobile: state.nav.isMobile,
  fetching: state.salesTracker.loading,
  checkLoading: state.salesTracker.checkLoading,
  detailedStoreLoading: state.salesTracker.detailedStoreLoading,
  detailedStoreNewLoading: state.salesTracker.detailedStoreNewLoading,
  fetchingSpin: state.salesTracker.fetchingSpin,
  salesTracker: state.salesTracker,
  selectedStoreTableDataLoading: state.salesTracker.selectedStoreTableDataLoading,
  storeProductsModalTableDataLoading: state.salesTracker.storeProductsModalLoading,
  storeProductsModalTableData: state.salesTracker.storeProductsModalTableData,
  error: state.salesTracker.error,
  view: state.salesTracker.view,
  activeView: state.salesTracker.activeView,
  breadcrumbs: state.breadcrumbs.totalBreadcrumbs,
  filterDates: state.salesTracker.filters.report_period,
  shopifyStore: state.shopifyStore,
  filtersLoading: state?.salesTracker?.productCards?.filtersLoading,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  setView: (data) => dispatch(Creators.setView(data)),
  setActiveView: (data) => dispatch(Creators.setActiveView(data)),
  addBreadCrumb: (data) => dispatch(BreadcrumbsActions.addBreadCrumb(data)),
  removeBreadCrumb: () => dispatch(BreadcrumbsActions.removeBreadCrumb()),
  getStores: (data) => dispatch(Creators.getTrackingStoresRequest(data)),
  clearBreadCrumbs: () => dispatch(BreadcrumbsActions.clearBreadCrumbs()),
  getDetailedStoreInfo: (data) => dispatch(Creators.getDetailedStoreInfoRequest(data)),
  getDetailedStoreInfoNew: (data) => dispatch(Creators.getDetailedStoreInfoNewRequest(data)),
  getDetailedStoreInfoCheckRequest: (data) => dispatch(Creators.getDetailedStoreInfoCheckRequest(data)),
  getProductListByStore: (data) => dispatch(Creators.getProductListByStoreRequest(data)),
  getStoreProductsModal: (data) => dispatch(Creators.getStoreProductsModalRequest(data)),
  getStoreProductsModalNext: (data) => dispatch(Creators.getStoreProductsModalNextRequest(data)),
  resetStoreProductsModal: () => dispatch(Creators.resetStoreProductsModal()),
  deleteStore: (data) => dispatch(Creators.deleteTrackingStoreByIDRequest(data)),
  connectProduct: (data) => dispatch(Creators.createTrackingProductFromStoreRequest(data)),
  deleteProduct: (data) => dispatch(Creators.deleteTrackingProductByIDFromStoreRequest(data)),
  getExpandableChart: (data) => dispatch(Creators.getExpandableChartRequest(data)),
  downloadSalesReport: (data) => dispatch(Creators.getSalesReportRequest(data)),
  downloadRevenueReport: (data) => dispatch(Creators.getRevenueReportRequest(data)),
  setFilters: (data) => dispatch(Creators.setFilters(data)),
  setPeriod: (data) => dispatch(Creators.setCheckedList(data)),
  resetFreePlanError: () => dispatch(Creators.resetFreePlanError()),
  changeVisibleModalShopify: (data) => dispatch(ShopifyCreators.changeVisibleModalShopify(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(StoreTrackerPage));
